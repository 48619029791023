/****************************************************************************************
                                       FONTS
*****************************************************************************************/

// DM SERIF TEXT

/* dm-serif-text-regular - latin */
@font-face {
    font-family: "DM Serif Text";
    font-style: normal;
    font-weight: 400;
    src: local(""),
         url("/assets/fonts/dm-serif-text-v5-latin-regular.woff2") format("woff2"),
         url("/assets/fonts/dm-serif-text-v5-latin-regular.woff") format("woff"),
         url("/assets/fonts/dm-serif-text-v5-latin-regular.ttf") format("truetype"),
         url("/assets/fonts/dm-serif-text-v5-latin-regular.svg#DMSerifText") format("svg"),
         url("/assets/fonts/dm-serif-text-v5-latin-regular.eot"); /* IE9 Compat Modes */
}

// ATKINSON HYPERLEGIBLE

@font-face {
    font-family: "Atkinson Hyperlegible";
    font-style: normal;
    font-weight: 400;
    src: local(""),
         url("/assets/fonts/Atkinson-Hyperlegible-Regular-102a.woff2") format("woff2"),
         url("/assets/fonts/Atkinson-Hyperlegible-Regular-102.woff") format("woff");
}

@font-face {
    font-family: "Atkinson Hyperlegible";
    font-style: italic;
    font-weight: 400;
    src: local(""),
         url("/assets/fonts/Atkinson-Hyperlegible-Italic-102a.woff2") format("woff2"),
         url("/assets/fonts/Atkinson-Hyperlegible-Italic-102.woff") format("woff");
}

@font-face {
    font-family: "Atkinson Hyperlegible";
    font-style: normal;
    font-weight: 700;
    src: local(""),
         url("/assets/fonts/Atkinson-Hyperlegible-Bold-102a.woff2") format("woff2"),
         url("/assets/fonts/Atkinson-Hyperlegible-Bold-102.woff") format("woff");
}

@font-face {
    font-family: "Atkinson Hyperlegible";
    font-style: italic;
    font-weight: 700;
    src: local(""),
         url("/assets/fonts/Atkinson-Hyperlegible-BoldItalic-102a.woff2") format("woff2"),
         url("/assets/fonts/Atkinson-Hyperlegible-BoldItalic-102.woff") format("woff");
}


// HEADINGS

/* playfair-display-regular - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-regular.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-regular.woff") format("woff");
}

/* playfair-display-600 - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-600.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-600.woff") format("woff");
}

/* playfair-display-500 - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-500.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-500.woff") format("woff");
}

/* playfair-display-800 - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 800;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-800.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-800.woff") format("woff");
}

/* playfair-display-700 - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-700.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-700.woff") format("woff");
}

/* playfair-display-italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 400;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-italic.woff") format("woff");
}

/* playfair-display-900 - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: normal;
	font-weight: 900;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-900.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-900.woff") format("woff");
}

/* playfair-display-500italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 500;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-500italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-500italic.woff") format("woff");
}

/* playfair-display-600italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 600;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-600italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-600italic.woff") format("woff");
}

/* playfair-display-800italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 800;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-800italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-800italic.woff") format("woff");
}

/* playfair-display-700italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 700;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-700italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-700italic.woff") format("woff");
}

/* playfair-display-900italic - latin */
@font-face {
	font-family: "Playfair Display";
	font-style: italic;
	font-weight: 900;
	src: local(""), url("/assets/fonts/playfair-display-v22-latin-900italic.woff2") format("woff2"), url("/assets/fonts/playfair-display-v22-latin-900italic.woff") format("woff");
}

// TEXT - CRIMSON TEXT DISABLED

@font-face {
	font-family: "Crimson Text";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-regular.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-regular.woff") format("woff");
}

@font-face {
	font-family: "Crimson Text";
	font-style: italic;
	font-weight: 400;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-italic.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-italic.woff") format("woff");
}

@font-face {
	font-family: "Crimson Text";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-600.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-600.woff") format("woff");
}

@font-face {
	font-family: "Crimson Text";
	font-style: italic;
	font-weight: 600;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-600italic.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-600italic.woff") format("woff");
}

@font-face {
	font-family: "Crimson Text";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-700.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-700.woff") format("woff");
}

@font-face {
	font-family: "Crimson Text";
	font-style: italic;
	font-weight: 700;
	src: local(""), url("/assets/fonts/crimson-text-v11-latin-700italic.woff2") format("woff2"), url("/assets/fonts/crimson-text-v11-latin-700italic.woff") format("woff");
}

