/*!
Site Name: Legende Holzdorf Audioguide Styles
Author: Jan Köpsel
Author URI: https://www.jankoepsel.com
Version: 1.0
*/

/****************************************************************************************
                                NORMALIZE
*****************************************************************************************/
@use "partials/normalize";

/****************************************************************************************
                                VARIABLES
*****************************************************************************************/
@use "partials/mixvars";

/****************************************************************************************
                                FONTS
*****************************************************************************************/
@use "partials/fonts";

/****************************************************************************************
                                MAIN STYLES
*****************************************************************************************/
@use "main";

/****************************************************************************************
                                CSS HACKS
*****************************************************************************************/
@use "partials/hacks";
