.leaflet-popup-content p {
  margin: 0px 0;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 0;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #374a86;
  box-shadow: 0;
}

.leaflet-popup-content a p {
  color: white;
}

.leaflet-popup-content a {
  color: white;
  font: 16px "Roboto", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
