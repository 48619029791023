/*********************************
    CSS HACKS AND FIXES
*********************************/


button:focused {
  outline: none;
}

// Keep the map sticky
.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0px;
}

// Loading animation style
.lds-ring {
  display: block;
  margin: auto auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 2px;
  border: 2px solid #374a86;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #374a86 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-square--blue .icon--white {
  fill: #fff;
  margin-bottom: -2px;
  width: 0.6em;
  margin-right: 5px;
}

.button-square--blue:hover .icon--white {
  fill: #374a86;
}

// FIX GALLERYY

.galleryy,
.small-map {
  margin-left: -20px;
  margin-right: -20px;
}
.galleryy .galleryy__list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0px;
  column-gap: 7px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.galleryy .galleryy__item {
  flex: 1 1 48%;
}
.galleryy .galleryy__image {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  transition: opacity 0.25s ease-in-out;
  vertical-align: bottom;
}
.galleryy .galleryy__image:hover {
  opacity: 0.7;
}
