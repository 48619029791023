@use "sass:math";

/****************************************************************************************
                                MIXINS & VARIABLES
*****************************************************************************************/

// FONTS
$font-head-bold: "NameSansHeading-Bold";
$font-head-light: "NameSansHeading-Light";
$font-head-reg: "NameSansHeading-Regular";

$font-text-black: "NameSanstext-Black";
$font-text-bold: "NameSanstext-Bold";
$font-text-med: "NameSanstext-Medium";
$font-text-reg: "NameSanstext-Regular";

// COLORS
$color-blue: #374a86;
$color-light-blue: #d7dbe7;
$color-med-blue: #b4bdd8;

$color-dark-gray: #a4a4a4;
$color-mid-gray: #dfdfdf;
$color-light-gray: #f0f0f0;

$color-white: #ffffff;
$color-black: #000;

/****************************************************************************************
							MEDIA QUERY MIXIN
*****************************************************************************************/

// BREAKPOINTS
$size-xxl: 1600px;
$size-xl: 1280px;
$size-lg: 960px;
$size-md: 840px;
$size-sm: 600px;
$size-xs: 420px;

@mixin MQ($canvas) {
	@if $canvas==XXL {
		@media only screen and (max-width: $size-xxl) {
			@content;
		}
	}
	@if $canvas==XL {
		@media only screen and (max-width: $size-xl) {
			@content;
		}
	}
	@if $canvas==LG {
		@media only screen and (max-width: $size-lg) {
			@content;
		}
	}
	@if $canvas==MD {
		@media only screen and (max-width: $size-md) {
			@content;
		}
	} @else if $canvas==SM {
		@media only screen and (max-width: $size-sm) {
			@content;
		}
	} @else if $canvas==XS {
		@media only screen and (max-width: $size-xs) {
			@content;
		}
	}
}

/****************************************************************************************
						COLORS MIXIN
*****************************************************************************************/

@mixin alpha-attribute($attribute, $color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	#{$attribute}: $solid-color;
	#{$attribute}: $color;
}

/****************************************************************************************
							FLUID TYPE MIXIN
*****************************************************************************************/

@function strip-unit($value) {
	@return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: math.unit($min-vw);
	$u2: math.unit($max-vw);
	$u3: math.unit($min-font-size);
	$u4: math.unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

// Defaults
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

///		EXAMPLE:
///		p.test {
///           @include fluid-type(360px, 1600px, 32px, 46px);
///			}
///     }
///     Example scales fonts between 1600px and 360px (viewport) from 46px to 32px (font-size)
