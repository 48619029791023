@use "partials/mixvars" as *;

html {
	width: 100%;
	height: 100%;
}

mybody {
	height: 100%;
	background: $color-white;
	color: $color-blue;
	position: relative;
	font-family: "Roboto";
	grid-template-columns: minmax(100px, 410px) 70px 1fr;
	display: grid;
}

/****************************************************************************************
						 OVERLAY
*****************************************************************************************/

// HALF OVERLAY - SIDEBAR OPEN
mybody {
	&:after {
		// Das Overlay ist 100ms langsamer im einblender als das Hauptmenü.
		transition: all 0.4s ease-in-out;
		opacity: 0;
	}
	&.sidebar-open {
		&:after {
			content: "";
			grid-column: 2 / span 2;
			grid-row: 1;
			background: rgba(0, 0, 0, 0.7);
			width: 100%;
			height: 100%;
			z-index: 10;
			opacity: 1;
		}
	}
}

// FULL OVERLAY (ONBOARDING)
.overlay {
	display: none;
}
mybody.overlay-open {
	.overlay {
		position: absolute;
		background: rgba(0, 0, 0, 0.7);
		top: 0;
		left: 0;
		width: 100%;
		min-height: 100%;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 50px 0;
		.overlay__content {
			width: 420px;
			max-width: 90%;
			background: $color-light-gray;
			padding: 25px;
			border-radius: 5px;
			h1 {
				margin-top: 0;
			}
			strong {
				font-size: 22px;
				font-weight: 700;
			}
			.flex-wrap {
				display: flex;
				align-items: top;
				gap: 10px;
				margin: 10px 0 25px 0;
				p {
					margin: 0;
				}
			}
		}
	}
}

/****************************************************************************************
							ICONS
*****************************************************************************************/
.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
	// SIZES
	&--small {
		width: 40px;
		height: 40px;
	}
	&--big {
		width: 45px;
		height: 45px;
	}
	// COLORS
	&--blue {
		fill: $color-blue;
	}
	&--white {
		fill: $color-white;
	}
}

/****************************************************************************************
							BUTTONS
*****************************************************************************************/

.button {
	background: none;
	border: none;
	cursor: pointer;
	text-align: center;
}

// ROUND BUTTONS
.button-round {
	background: $color-white;
	border-radius: 100px;
	margin: 0 5px;
	padding: 0;
	border: 0;
	transition: all 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		cursor: pointer;
		background: $color-blue;
		svg {
			fill: $color-white;
		}
	}
	svg {
		fill: $color-blue;
	}

	// SIZES
	&--tiny {
		width: 35px;
		height: 35px;
		svg {
			width: 13px;
			height: 15px;
		}
	}
	&--small {
		width: 45px;
		height: 45px;
		svg {
			height: 15px;
		}
	}
	&--big {
		height: 55px;
		width: 55px;
		svg {
			height: 25px;
		}
	}

	// BORDER
	&--border {
		border: 1.1px solid $color-blue;
	}

	// BUTTON WITH CIRCULAR PROGRESS BAR
	&--progress {
		position: relative;
		svg {
			z-index: 2;
		}
		&:before {
			content: "";
			border-radius: 100px;
			display: block;
			height: 90%;
			width: 90%;
			position: absolute;
			background: $color-white;
			z-index: 1;
			transition: all 0.1s;
		}
		&:hover:before {
			background: $color-blue;
		}
		&:after {
			border-radius: 100px;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			background-image: conic-gradient($color-blue 0%, $color-blue 35%, $color-white 35%, $color-white 100%);
			transition: all 0.1s;
		}
		&:hover:after {
			background-image: conic-gradient($color-white 0%, $color-white 35%, $color-blue 35%, $color-blue 100%);
		}
	}
}

// SQUARE BUTTONS
.button-square {
	background: $color-white;
	color: $color-blue;
	border: 0;
	padding: 10px 15px;
	border-radius: 3px;
	text-align: center;
	max-height: 40px;
	cursor: pointer;
	font-family: "Roboto";
	font-weight: 300;
	transition: all 0.3s;
	&:hover {
		color: $color-blue;
		background: $color-light-blue;
	}
	// BLUE BUTTON
	&--blue {
		background: $color-blue;
		color: $color-white;
	}
	// BUTTON WITH LINEAR PROGRESS BAR
	&--progress {
		background-image: linear-gradient(90deg, $color-light-blue 0%, $color-light-blue 35%, $color-white 35%, $color-white 100%);
	}
}

/****************************************************************************************
							GLOBAL CLASSES
*****************************************************************************************/
p,
ul {
	font-family: "Roboto";
	font-weight: 300;
	line-height: 1.4;
	@include fluid-type(360px, 480px, 16px, 18px);
}

b {
	font-family: $font-text-bold;
	font-weight: normal;
}

a {
	text-decoration: none;
	color: inherit;
	word-wrap: none;
	&:hover {
		text-decoration: underline;
	}
}

ul {
	list-style: disc;
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

/****************************************************************************************
									HEADER
*****************************************************************************************/

// PAGE HEADER WITHOUT IMAGE (Impressum - Umgebungskarte ...)
.page-header {
	position: relative;
	background: $color-white;
	&__topbar {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		h1 {
			font-family: "Roboto";
			font-weight: 500;
			margin: 0;
		}
		.icon {
			&:hover {
				opacity: 0.6;
			}
		}
	}
}

.page-header--sticky {
	display: none;
	position: sticky;
	z-index: 3;
	top: 0;
	&.is-visible {
		display: block;
	}
}

// PAGE HEADER WITH IMAGE (Touren - Stationen - Beiträge - Startseite)
.page-header-hero {
	position: relative;
	&__topbar {
		position: absolute;
		top: 0;
		z-index: 2;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.icon {
			&:hover {
				opacity: 0.6;
			}
		}
		// Menübutton nach rechts bewegen falls er alleine dargestellt wird.
		& > *:last-child {
			margin-left: auto;
		}
	}

	&__image {
		img {
			vertical-align: bottom;
			height: 300px;
			width: 480px;
		}
		&::after {
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			display: block;
			top: 0;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
		}
	}

	&__meta {
		position: absolute;
		width: 100%;
		padding: 0 20px;
		bottom: 25px;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__brand {
		position: absolute;
		width: 100%;
		padding: 0 20px;
		bottom: 25px;
		z-index: 2;
		display: flex;
		align-items: center;
		.page-header-hero__logo {
			height: 55px;
			//width: 180px;
			margin-inline-end: 20px;
			margin-bottom: 25px;
			svg {
				width: 100%;
				height: 100%;
				fill: $color-white;
			}
		}
		.page-header-hero__separator {
			height: 50px;
			width: 2px;
			background: $color-white;
		}
		.page-header-hero__title {
			padding-inline-start: 20px;
			@include fluid-type(360px, 480px, 26px, 34px);
		}
	}

	&__title {
		color: $color-white;
		h1 {
			font-family: "Roboto";
			font-weight: 500;
			margin: 0;
		}
		.subtitle {
			padding-top: 2px;
			span {
				font-family: "Roboto";
				font-weight: 300;
				font-size: 18px;
				&:after {
					content: "•";
					margin: 0 5px;
				}
				&:last-of-type::after {
					content: "";
				}
			}
		}
	}

	&__logoname {
		padding-inline-end: 20px;
	}
}

/****************************************************************************************
							MAIN NAVIGATION
*****************************************************************************************/
.sidebar-open {
	.sidebar {
		display: flex;
		flex-direction: column;
		z-index: 10;
		height: 100vh;
		background: $color-white;
		transform: translateX(410px);
		transform: translate3d(410px, 0, 0);
	}
}
.sidebar {
	transition: 300ms ease-out all;
	z-index: 999;
	grid-column: 1 / span 1;
	grid-row: 1;
	width: 100%;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	display: none;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: $color-dark-gray $color-light-gray;
	font-family: "Roboto";
	position: relative;
	left: -410px;
	// Scrollbar Styling
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		background: $color-light-gray;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color-dark-gray;
		border-radius: 5px;
		border: 2px solid $color-light-gray;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__head {
		position: relative;
		.icon-close {
			position: absolute;
			top: 25px;
			right: 25px;
			fill: $color-white;
			z-index: 2;
		}
		.icon-logo {
			position: absolute;
			width: 200px;
			height: 100px;
			max-width: 50%;
			top: -5px;
			left: 25px;
			fill: $color-white;
			z-index: 2;
		}
		img {
			vertical-align: bottom;
			height: 300px;
			width: 480px;		  
		}
		&:after {
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			display: block;
			top: 0;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
		}
	}
	// HAUPTNAVIGATION
	.tour-nav {
		padding: 20px 0 0 20px;
		ul {
			padding: 0;
			ul {
				margin: 0 0 0 25px;
			}
			li a {
				@include fluid-type(360px, 480px, 26px, 32px);
			}
			li li a {
				@include fluid-type(360px, 480px, 22px, 28px);
			}
		}
	}
	hr.separator {
		width: calc(100% - 40px);
		border: 2px solid $color-blue;
		background: $color-blue;
		margin: 20px;
	}
	// UNTERNAVIGATION
	.sub-nav {
		flex-grow: 1;
		padding: 0 20px;
		ul {
			li a {
				@include fluid-type(360px, 480px, 20px, 30px);
			}
		}
	}
	// RECHTLICHE LINKS (IMPRESSUM - DATENSCHUTZ)
	.legal-nav {
		padding: 20px;
		ul {
			display: flex;
			li {
				margin: 0 10px 0 0;
			}
			li a {
				color: $color-dark-gray;
			}
		}
	}
}

/****************************************************************************************
							PAGE WRAP FOR MAIN CONTENT
*****************************************************************************************/
.page-wrap {
	grid-column: 1 / span 2;
	grid-row: 1;
	background: $color-light-gray;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
	z-index: 2;
	min-height: 100vh;
	// Scrollen nur im .page-wrap erlauben
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: $color-dark-gray $color-light-gray;
	// Scrollbar Styling
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		background: $color-light-gray;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color-dark-gray;
		border-radius: 5px;
		border: 2px solid $color-light-gray;
	}
}

/****************************************************************************************
							THE MAP (DESKTOP)
*****************************************************************************************/
.map {
	z-index: 0;
	grid-area: map;
	grid-column: 3 / span 1;
	grid-row: 1;
	overflow: hidden;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

/****************************************************************************************
							PAGE CONTENT
*****************************************************************************************/

.content {
	padding: 0 20px 50px 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Roboto";
	font-weight: 400;
}

.section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	h2 {
		font-size: 26px;
	}
}

// Kleine Karte
.small-map {
	margin-left: -20px;
	margin-right: -20px;
	position: relative;
	text-align: center;
	.button {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		z-index: 2;
		display: block;
		width: 70%;
		margin: 0 auto;
	}
}

// BILDER GALERIE
.gallery {
	margin-left: -20px;
	margin-right: -20px;
	.gallery__list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.gallery__item {
		flex: 1 1 48%;
	}
	.gallery__image {
		width: 100%;
		height: auto;
		object-fit: cover;
		transition: opacity 0.25s ease-in-out;
		vertical-align: bottom;
		&:hover {
			opacity: 0.7;
		}
	}
}

// Stations Buttons
.station-buttons {
	margin-left: -20px;
	margin-right: -20px;
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	background: $color-white;
	margin-bottom: -50px;
	.button {
		margin-inline: 10px;
		flex-grow: 1;
	}
}

// Audio Items (Touren - Stationen - Beträge)
.audio-items {
	margin-left: -20px;
	margin-right: -20px;
	.audio-items__list {
		.audio-items__single {
			grid-gap: 15px;
			display: grid;
			grid-template-columns: 25% 1fr 50px 0;
			margin: 5px 0 0 0;
			background: $color-white;
			width: 100%;
			justify-content: center;
			align-items: center;
			&__image {
				vertical-align: bottom;
				aspect-ratio: 1 / 1;
			}
			&__text {
				display: flex;
				align-items: center;
			}
			&__title {
				font-family: "Roboto";
				font-size: 24px;
				font-weight: 300;
			}
			&__meta {
				color: $color-dark-gray;
				font-size: 16px;
			}
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&.audio-items--section {
		.audio-items__list {
			.audio-items__single {
				grid-gap: 15px;
				display: grid;
				grid-template-columns: 25% 1fr;
			}
		}
	}
}

// Auflistung von Förderern/Partnern - Logos
.partners-items {
	&__list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__single {
		flex: 0 0 45%;
		margin: 10px 0 0 0;
	}
}

// Mitwirkende
.persons {
	.single-person {
		display: grid;
		grid-template-columns: 60px 60px 1fr;
		grid-template-rows: 60px 60px 50px 1fr;
		margin: 0 0 25px 0;
		&__image {
			grid-row: 1 / span 2;
			grid-column: 1 / span 2;
			z-index: 2;
		}
		&__play {
			grid-row: 3 / span 1;
			grid-column: 1 / span 2;
			z-index: 2;
			width: 100%;
			margin: 10px 0 0 0;
		}
		&__text {
			background: $color-white;
			grid-row: 2 / span 3;
			grid-column: 2 / span 2;
			padding: 30px 25px 20px 75px;
			.name {
				font-size: 26px;
				font-weight: 300;
				font-family: "Roboto";
			}
			.subtitle {
				color: $color-dark-gray;
				margin: 0 0 10px 0;
				font-size: 22px;
			}
		}
	}
}

/****************************************************************************************
* 							PLAYER
*****************************************************************************************/

// Damit der Player nicht den Inhalt verdeckt, schieben wir den Inhalt nach oben sobald der Player aktiv ist.
// Wird als Klasse im "mybody" hinzugefügt.
.player-active {
	.content {
		// Höhe des Players
		padding-bottom: 220px;
	}
	// Player anzeigen
	.player {
		transform: translateY(0px);
		position: fixed;
		z-index: 10;
	}
}

.mini-player-active {
	.content {
		// Höhe des Players
		padding-bottom: 77px;
	}
	// Miniplayer anzeigen
	.player-mini {
		transform: translateY(0px);
		position: fixed;
		z-index: 9;
	}
}

.player {
	transform: translateY(220px);
	background: $color-blue;
	max-width: 480px;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 20px;
	color: $color-white;
	transition: all .8s;
	position: fixed;
	align-items: center;

	// Bedienbuttons (Skip - Play/Pause)
	&__buttons {
		display: flex;
		margin-top: -25px;
		justify-content: space-between;
		align-items: center;
		.button-round {
			@extend .has-shadow;
		}
	}

	&__inner-buttons {
		display: flex;
		align-items: center;
	}

	// TITEL UND TRACKNUMMER
	&__meta {
		display: flex;
		justify-content: space-between;
		margin: 15px 0;
		font-size: 22px;

		.title {
			font-family: "Roboto";
			font-weight: 500;
		}
		.subtitle {
			font-size: 18px;
		}
		.track-num {
			font-weight: 500;
			font-size: 22px;
		}
	}
	// ZEIT ANGABE (Gesamt + Vergangen)
	&__time {
		display: flex;
		justify-content: space-between;
		margin: 6px 0 10px;
		font-size: 22px;
		font-weight: 300;
	}

	// Fortschrittsbalken
	&__progress {
		height: 3px;
		background: $color-white;
		position: relative;
		border-radius: 2px;
		.tracker {
			position: absolute;
			width: 20px;
			height: 20px;
			background: $color-white;
			border-radius: 10px;
			top: -9px;
			// positon des fortschritts punkt (20px abgezogen wegen der eigen breite)
			left: calc(10% - 20px);
			cursor: pointer;
		}
	}

	// Untere Navigation im Player (nächste Station - Zurück zur Startseite)
	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		background: $color-light-blue;
		margin: 0 -20px;
		padding: 5px 20px;
		.button-square {
			margin: 5px 0;
			&:hover {
				color: $color-white;
				background: $color-blue;
				.icon {
					fill: $color-white;
				}
			}
		}
		.player__next-station,
		.player__prev-station {
			width: 40px;
			height: 40px;
			padding: 0;
		}
		.station-title {
			font-size: 22px;
			color: $color-blue;
			font-weight: 300;
			max-height: 40px;
		}
		.player__wide {
			flex-grow: 1;
			margin: 0 5px;
		}
	}
}

/****************************************************************************************
* 							MINI-PLAYER
*****************************************************************************************/
.player-mini {
	transform: translateY(100px);
	background: $color-blue;
	max-width: 480px;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 20px 0px 20px;
	color: $color-white;
	transition: all 1.2s;
	position: fixed;
	display: flex;
	align-items: center;
	.input-range__slider-container {
		display: none;
	}
	.input-range__track {
		height: 0.2rem;
	}
	// Bedienbuttons (Skip - Play/Pause)
	&__buttons {
		display: flex;
		// margin-top: -80px;
		justify-content: space-between;
		align-items: center;
		.button-round {
			@extend .has-shadow;
			&:hover {
				background: $color-med-blue;
			}
		}
	}

	&__mid {
		// background: red;
		width: 100%;
		margin: 0 10px;
	}

	// TITEL UND TRACKNUMMER
	&__meta {
		display: flex;
		justify-content: space-between;
		margin: 5px 0 10px 0;
		font-size: 22px;
		.title {
			font-family: "Roboto";
			font-weight: 500;
		}
	}

	// ZEIT ANGABE (Gesamt + Vergangen)
	&__time {
		display: flex;
		justify-content: space-between;
		//margin: 5px 0 10px 0;
		font-size: 22px;
		font-weight: 300;
	}

	// Fortschrittsbalken
	&__progress {
		//height: 3px;
		//position: relative;
		//background: linear-gradient(90deg, $color-med-blue 0%, $color-med-blue 35%, $color-white 35%, $color-white 100%);
		//border-radius: 2px;
	}

	// Player Ausklappen Button
	&__toggle {
		width: 45px;
		position: absolute;
		right: 20px;
		bottom: 55px;
		//margin-top: -75px;
		button:hover {
			background: $color-med-blue;
		}
	}
}

/****************************************************************************************
							HELPER AND SPECIAL CLASSES
*****************************************************************************************/

.desktop-only {
	@include MQ(LG) {
		display: none;
	}
}

.mobile-only {
	display: none;
	@include MQ(LG) {
		display: static;
	}
}

// Für Links die nicht funtionieren sollen (Bspw. weiterskippen im PLayer obwohl das Ende erreicht ist.)
.is-disabled {
	cursor: not-allowed !important;
	& > * {
		opacity: 0.5;
	}
}

// Falls Flexbox zum spacing genutzt wird manchmal ganz praktisch das Element nur "visuell" auszublenden.
.is-invisible {
	visibility: hidden;
}

.has-shadow {
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.has-soft-shadow {
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.no-wrap {
	white-space: nowrap;
}

.hidden {
	display: none;
}

// SKIP TO CONTENT LINKS
.skip-to-content-link {
	font-family: $font-text-med;
	background: $color-black;
	color: $color-white;
	height: 30px;
	left: 50%;
	position: absolute;
	transform: translateY(-1000%);
	transition: transform 0.3s;
	line-height: 1;
	padding: 5px 20px;
	z-index: 5;
}

.skip-to-content-link:focus {
	transform: translateY(0%);
}
